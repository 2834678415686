import React from "react"
import { Link } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import Loader from 'react-loader-spinner';
import Layout from "../components/layout"
import Api from "../utils/api.js"

const api = new Api()

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
  }

  reload() {
  }

  render() { return (
    <Layout>

      {/* <div class="header-line"></div> */}

      <div class="title-container">
        <div class="title-section-1">
          <div class="title-text">Moody<br/>Monsteras</div>
          <img src="/images/title-background.png"/>
        </div>
        <div class="title-section-2">
          <div class="title-info-text">A collection of Monstera houseplant NFTs. Thoughtfully designed, and carefully crafted, to bring joy to your home in the metaverse!</div>
        </div>
      </div>

      <hr/>

      <div class="section-title">Why Mint a Moody Monstera?</div>
      <div class="examples-container">
        <div class="examples-info">
          <div class="examples-info-text">Feeling stressed out from another crypto crash? Did Elon's latest tweet fail to pump your bags? Does a host's comment on CNBC look like another top signal?<br/><br/>Monsteras, also known as Swiss Cheese Plants, have been been a source of relaxation and comfort for a millennia*. Moody Monsteras were created to bring those same good vibes into your nook of the metaverse!</div>
        </div>
        <div class="examples-display">
          <div class="examples-img-container">
            <img src="/images/example.jpg"/>
            <img src="/images/example10.jpg"/>
            <img src="/images/example12.jpg"/>
            <img src="/images/example11.jpg"/>
            <img src="/images/example5.jpg"/>
            <img src="/images/example6.jpg"/>
            <img src="/images/example9.jpg"/>
            <img src="/images/example9.jpg"/>
          </div>
        </div>
      </div>

      <hr/>

      <div class="section-title">Details</div>
      <div class="launch-container">
        <div class="launch-item-container">
          <div class="launch-item">
            <span>2900<br/>Monsteras</span>
          </div>
          <div class="launch-item">
            <span>FREE<br/>MINT</span>
          </div>
        </div>
        <div class="launch-item-container">
          <div class="launch-item">
            <span>1-5 per<br/>Transaction</span>
          </div>
          <div class="launch-item">
            <span>Available<br/>Now!</span>
          </div>
        </div>
      </div>

      <hr/>

      {/* <div class="section-title">Goals</div>
      <div class="goals-container">
        <div class="goals-item-container">
        <div class="goals-item">
            <div class="goals-item-title">10% Minted</div>
            <div class="goals-item-text">The website will be updated to give Monstera owners the ability to name their Monsteras on the blockchain! (Just like your IRL houseplants... don't lie!) Monstera names will live on through trades and time forever! Or until a new owner renames them. 😉</div>
          </div>
          <div class="goals-item-arrow">
            <div><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/></svg></div>
          </div>
          <div class="goals-item">
            <div class="goals-item-title">30% Minted</div>
            <div class="goals-item-text">1K USD will be donated to a community chosen non-profit working to protect tropical&#8239;forests!</div>
          </div>
          <div class="goals-item-arrow">
          <div><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/></svg></div>
          </div>
          <div class="goals-item">
            <div class="goals-item-title">60% Minted</div>
            <div class="goals-item-text">5K USD will be donated to a community chosen non-profit working to protect tropical&#8239;forests!</div>
          </div>
          <div class="goals-item-arrow">
          <div><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/></svg></div>
          </div>
          <div class="goals-item">
            <div class="goals-item-title">90% Minted</div>
            <div class="goals-item-text">10K USD will be donated to a community chosen non-profit working to protect tropical&#8239;forests!</div>
          </div>
          <div class="goals-item-arrow">
          <div><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/></svg></div>
          </div>
          <div class="goals-item">
            <div class="goals-item-title">100% Minted</div>
            <div class="goals-item-text"><b class="underline">Monstera "Chonk" Events Begin!</b><br/>A unique feature of Moody Monsteras that rewards holders with access to new collections and collaborations!</div>
          </div>
        </div>
      </div> */}

      {/* <hr/>

      <div class="section-title">Monstera "Chonks"</div>
      <div class="cuttings-container">
        <div class="cuttings-item-container">
          <div class="cuttings-item">
            <div class="cuttings-item-text">Soon after the mint sells out, and every few months after, we will allow every Moody Monstera owner to take a “cutting” from their Monstera! Taking a cutting <b class="underline">will mint a new NFT called a Monstera Node or "Chonk"</b>, which will grow and once revealed, become an entirely new plant <b class="underline">with traits based on the Monstera parent they came from!</b><br/><br/>These new plants may become new collections, have different art styles, and/or be the result of collaborations! Cutting events will be a way to reward our collection holders as well as grow our community. </div>
          </div>
        </div>
      </div>

      <hr/> */}

      <div class="section-title">The Team</div>
      <div class="team-container">
        <div class="team-member-container">
          <div class="team-member">
            <div class="team-member-name"><a href="https://twitter.com/unfortun8">UnfortuN8</a></div>
            <img src="/images/team-avatar-1.png"/>
            <div class="team-member-info">A technologist, developer, and designer, who has built a diverse set of products over the years from a messaging bot platform to a crypto powered mobile game.</div>
          </div>
          <div class="team-member">
            <div class="team-member-name"><a href="https://twitter.com/thecarnivallion">TheCarnivallion</a></div>
            <img src="/images/team-avatar-2.png"/>
            <div class="team-member-info">Amazing watercolor and oil painter, houseplant collector, and digital artist of Moody Monsteras! Plant mom to a thriving Monstera Albo.</div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="section-title">Stay in Touch!</div>
      <div class="mint-cta-container">
        <div class="mint-cta">
          <div class="mint-cta-text">Follow us on <Link to="https://twitter.com/moodymonsteras">Twitter</Link> or join our <Link to="https://discord.gg/g5hsye4xUN">Discord</Link> to help build our community!</div>
        </div>
      </div>

      <div class="asterisks-container">
        <div class="asterisks">*Assumption based on zero research</div>
      </div>

    </Layout>
  )}
}
